import React from 'react';
import styled from 'styled-components';

const FlexContainerStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${props => !props.justifyContent ? 'space-around' : props.justifyContent};
    align-items: ${props => !props.alignItems ? 'center' : props.alignItems};
    padding: 0 20rem;
    width: 100%;

    ${props => props.column && `
        flex-direction: column;
    `}

    @media ${props => props.theme.media.laptop} {
        padding: 0 15rem;

    }
    @media ${props => props.theme.media.tablet} {
        flex-direction: column;
        padding: 0 10rem;

    }
    @media ${props => props.theme.media.mobileL} {
        padding: 0 2rem;

    }
`;


const FlexContainer = (props) => {
return (
    <FlexContainerStyled id={props.id} {...props}>
        {props.children}
    </FlexContainerStyled>
)
}


export default FlexContainer;