import React from "react"
import { Helmet } from "react-helmet"

const IDHelmet = props => {
  const image = "https://www.issydennis.com/" + props.image
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />

      <title>{props.title}</title>
      <meta name="description" content={props.description} />

      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={props.url} />

      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default IDHelmet
