import React from "react"
import styled from "styled-components"

const ParagraphStyled = styled.p`
  padding: 5px 0;
  font-size: 1rem;
  color: ${props =>
    !props.textColor ? props.theme.colors.darkFont : props.textColor};
`

const paragraph = props => {
  return (
    <ParagraphStyled {...props} textColor={props.textColor}>
      {props.children}
    </ParagraphStyled>
  )
}

export default paragraph
